import {
  FormInputInterface,
  FormInterface,
  JobInterface,
  QuestionInterface,
} from '@disc-workspace/transport';

import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GraphQLService {
  constructor(private apollo: Apollo) {}

  fetchJobs() {
    const query = gql`
      query fetchJobs {
        jobs {
          id
          slug
          name
        }
      }
    `;
    return this.apollo
      .query({ query })
      .pipe(map((x) => x.data['jobs'] as JobInterface[]));
  }

  fetchQuestions() {
    const query = gql`
      query getQuestions {
        questionList: getQuestions {
          id
          answerD
          answerI
          answerS
          answerC
        }
      }
    `;
    return this.apollo
      .query({ query })
      .pipe(map((x) => x.data['questionList'] as QuestionInterface[]));
  }

  fetchForm(id: string) {
    const query = gql`
      query getForm($id: String!) {
        form(id: $id) {
          id
          answerD
          answerI
          answerS
          answerC
          user {
            fullName
          }
        }
      }
    `;
    return this.apollo
      .query({ query, variables: { id } })
      .pipe(map((x) => x.data['form'] as FormInterface));
  }

  submitExamine(data: FormInputInterface) {
    const mutation = gql`
      mutation submitForm($data: FormInput!) {
        form: submitExamine(input: $data) {
          id
          answerD
          answerI
          answerC
          answerS
        }
      }
    `;
    return this.apollo
      .mutate({ mutation, variables: { data } })
      .pipe(map((x) => x.data['form'] as FormInterface));
  }
}
