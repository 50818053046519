import { environmentDev, environmentProd } from './base';

import { IEnvironment } from './environment.interface';
import { isDevMode } from '@angular/core';

/**
 * Workspace shared environment for ease of use across all shared code and apps
 * You can ignore this file - values here only used for unit testing
 * Real Values are filled in by each runtime/platform/app
 */
export const environment: IEnvironment = {
  production: false,
  // ...(isDevMode() ? environmentDev : environmentProd),
  // graphqlUrl: 'http://10.0.84.192:4400/graphql',
  // graphqlUrl: 'https://api.disc.dili.group/graphql',
};
