import { ButtonComponent } from './button/button.component';
import { ButtonIconComponent } from './button-icon/button-icon.component';
import { InfographicComponent } from './infographic/infographic.component';

export const UI_COMPONENTS = [
  ButtonComponent,
  ButtonIconComponent,
  InfographicComponent,
];

export * from './button/button.component';
export * from './button-icon/button-icon.component';
export * from './infographic/infographic.component';
