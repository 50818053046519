import { Component, Input } from '@angular/core';

import { BaseComponent } from '@disc-workspace/xplat/core';

@Component({
  selector: 'disc-workspace-infographic',
  templateUrl: 'infographic.component.html',
  styleUrls: ['infographic.component.scss'],
})
export class InfographicComponent extends BaseComponent {
  @Input() images_url: string = "";
  constructor() {
    super();
  }
}
