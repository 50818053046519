import { Component, Input } from '@angular/core';

import { BaseComponent } from '@disc-workspace/xplat/core';

@Component({
  selector: 'disc-workspace-button',
  templateUrl: 'button.component.html',
  styleUrls: ['button.component.scss'],
})
export class ButtonComponent extends BaseComponent {
  @Input() buttonText = '';
  @Input() disabled = false;
  constructor() {
    super();
  }
}
