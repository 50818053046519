import { BehaviorSubject, Subject } from 'rxjs';
import {
  FormInputInterface,
  FormInterface,
  JobInterface,
  QuestionInterface,
} from '@disc-workspace/transport';

import { GraphQLService } from './graphql.service';
import { Injectable } from '@angular/core';
import shuffle from 'lodash.shuffle';
import values from 'lodash.values';

interface AnswerPayload {
  questionId: number;
  answer: number;
}

interface QuestionRender {
  id: number;
  answers: string[];
  map: number[];
}

@Injectable({
  providedIn: 'root',
})
export class DISCService {
  initialized = false;
  validateAll = true;
  validate = true;
  form: FormInputInterface;

  questions$: BehaviorSubject<QuestionRender[]> = new BehaviorSubject<
    QuestionRender[]
  >([]);
  jobs$: BehaviorSubject<JobInterface[]> = new BehaviorSubject<JobInterface[]>(
    []
  );
  result$: Subject<FormInterface> = new Subject();
  route$: Subject<string> = new Subject();

  questionList: QuestionInterface[];
  currentQuestion = 0;
  selectedAnswer: number;

  answer: { [k: string]: AnswerPayload } = {};

  constructor(private graphqlService: GraphQLService) {}

  /**
   * Shuffle list of answer for each question and memoize the question id
   */
  set questions(list: QuestionInterface[]) {
    this.questionList = list;
    const questions$ = this.questionList.map((question) => {
      const { id, answerD, answerI, answerS, answerC } = question;
      const questionArray = shuffle(
        values({ answerD, answerI, answerS, answerC }).map((answer, index) => ({
          answer,
          index,
        }))
      );
      const map = questionArray.map((q) => q.index);
      const answers = questionArray.map((q) =>
        q.answer.replace('(', '\n\r').replace(')', '')
      );
      return { id, answers, map };
    });
    this.questions$.next(questions$);
  }

  getQuestionList() {
    return this.questions$;
  }

  getForm() {
    return this.form;
  }

  countCompletedQuestion() {
    return values(this.answer).length;
  }

  isQuestionCompleted(questionIndex: number) {
    return questionIndex in this.answer;
  }

  isLastQuestion() {
    return this.currentQuestion === this.questionList.length - 1;
  }

  selectAnswer(answerIndex: number) {
    this.selectedAnswer = answerIndex;
    this.answer[this.currentQuestion] = {
      questionId: this.questionList[this.currentQuestion].id,
      answer: this.selectedAnswer,
    };
    this.validate = this.validateAll = true;
  }

  selectQuestion(questionIndex: number) {
    if (questionIndex > -1 && questionIndex < this.questionList.length) {
      this.currentQuestion = questionIndex;
      this.selectedAnswer = this.answer[this.currentQuestion]
        ? this.answer[this.currentQuestion].answer
        : -1;
    }
  }

  /**
   * Store user input before do the test
   * @param userData
   */
  storeUser(userData: Omit<FormInputInterface, 'answers'>) {
    this.form = {
      ...(this.form || {}),
      ...userData,
    };
  }

  storeExtraInfo(userData: Pick<FormInputInterface, 'jobSlug' | 'birthday'>) {
    this.form = {
      ...(this.form || {}),
      ...userData,
    };
  }

  /**
   * Store referal slug in the link
   * @param slug
   */
  storeReferalSlug(slug: string) {
    this.form = {
      ...(this.form || {}),
      referalSlug: slug,
    };
  }

  async init() {
    const loadAll = [this.loadQuestions(), this.loadJobs()];
    await Promise.all(loadAll).then(() => {
      this.route$.next('/form');
    })
  }

  async loadQuestions() {
    (await this.graphqlService.fetchQuestions()).subscribe((questions) => {
      this.questions = questions;
      this.initialized = true;
      this.validate = true;
    });
  }

  async loadJobs() {
    (await this.graphqlService.fetchJobs()).subscribe((jobs) => {
      console.log(jobs);
      this.jobs$.next(jobs);
    });
  }

  loadForm(id: string) {
    return this.graphqlService.fetchForm(id);
  }

  saveAnswers() {
    if (this.countCompletedQuestion() === this.questionList.length) {
      this.route$.next('/before-submit');
    } else {
      this.validateAll = false;
    }
  }

  submit() {
    const answerRemap = values(this.answer);
    const payload = { ...this.form, answers: answerRemap };
    this.graphqlService.submitExamine(payload).subscribe((form) => {
      this.result$.next(form);
    });
  }
}
