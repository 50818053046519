import { Component, Input } from '@angular/core';

import { BaseComponent } from '@disc-workspace/xplat/core';

@Component({
  selector: 'disc-workspace-button-icon',
  templateUrl: 'button-icon.component.html',
  styleUrls: ['button-icon.component.scss'],
})
export class ButtonIconComponent extends BaseComponent {
  @Input() buttonText = '';
  @Input() disabled: boolean;
  constructor() {
    super();
  }
}
